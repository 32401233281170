import React from 'react';
import { Button, Input } from 'antd';

export default ({ confirm, setSelectedKeys, selectedKeys }) => (
  <div className="customerFilterDropdown">
    <Input
      placeholder="Buscar"
      value={selectedKeys[0]}
      onPressEnter={confirm}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    />

    <div className="customerFilterButtonContainer">
      <Button className="filter-button" type="primary" onClick={confirm}>
        Filtrar
      </Button>
    </div>
  </div>
);
