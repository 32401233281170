import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Card,
  Row,
  Col,
  Button,
  Skeleton,
  Typography,
  Modal,
  notification,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import {
  businessFragments,
  branchFragments,
  discountFragments,
} from '../../../../fragments';
import BasicTable from '../../../shared/basicTable';
import DiscountForm from './form';

const GET_BUSINESS = gql`
  query GetBusiness($id: Int!) {
    business: getBusiness(id: $id) {
      ...BusinessFields
      branches {
        ...BranchFields
      }
      discounts {
        ...DiscountFields
      }
    }
  }
  ${businessFragments.business}
  ${branchFragments.branch}
  ${discountFragments.discount}
`;

const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($attributes: DiscountAttributes!) {
    result: createDiscount(attributes: $attributes) {
      discount {
        ...DiscountFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${discountFragments.discount}
`;

const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($id: Int!, $attributes: DiscountAttributes!) {
    result: updateDiscount(id: $id, attributes: $attributes) {
      discount {
        ...DiscountFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${discountFragments.discount}
`;

const DESTROY_DISCOUNT = gql`
  mutation DestroyDiscount($id: Int!) {
    result: destroyDiscount(id: $id) {
      discount {
        ...DiscountFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${discountFragments.discount}
`;

export default () => {
  const [displayNewDiscountForm, setDisplayNewDiscountForm] = useState(false);
  const [displayDiscountForm, setDisplayDiscountForm] = useState();
  const { id } = useParams();

  const { data: { business = {} } = {}, loading } = useQuery(GET_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(id),
    },
  });

  const [
    createDiscount,
    { data: { result: createResult = {} } = {}, loading: creatingDiscount },
  ] = useMutation(CREATE_DISCOUNT, {
    update: (client, { data }) => {
      if (data.result.discount) {
        notification.success({
          message: 'Bien!',
          description: 'Descuento creado correctamente.',
        });

        setDisplayNewDiscountForm(false);

        const previousData = client.readQuery({
          query: GET_BUSINESS,
          variables: {
            id: Number(id),
          },
        });

        client.writeQuery({
          query: GET_BUSINESS,
          variables: {
            id: Number(id),
          },
          data: {
            ...previousData,
            business: {
              ...previousData.business,
              discounts: [
                ...previousData.business.discounts,
                data.result.discount,
              ],
            },
          },
        });
      }
    },
  });

  const [
    updateDiscount,
    { data: { result: updateResult = {} } = {}, loading: updatingDiscount },
  ] = useMutation(UPDATE_DISCOUNT, {
    update: (_, { data }) => {
      if (data.result.discount) {
        notification.success({
          message: 'Bien!',
          description: 'Descuento actualizado correctamente.',
        });

        setDisplayDiscountForm(false);
      }
    },
  });

  const [destroyDiscount, { loading: destroyingDiscount }] = useMutation(
    DESTROY_DISCOUNT,
    {
      update: (client, { data }) => {
        if (data.result.discount) {
          notification.success({
            message: 'Bien!',
            description: 'Descuento eliminado correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_BUSINESS,
            variables: {
              id: Number(id),
            },
          });

          client.writeQuery({
            query: GET_BUSINESS,
            variables: {
              id: Number(id),
            },
            data: {
              ...previousData,
              business: {
                ...previousData.business,
                discounts: previousData.business.discounts.filter(
                  b => b.id !== data.result.branch.id,
                ),
              },
            },
          });
        }
      },
    },
  );

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/businesses">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        {business && business.name ? (
          business.name
        ) : (
          <Skeleton paragraph={false} active />
        )}
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="primary" onClick={() => setDisplayNewDiscountForm(true)}>
          Añadir descuento
        </Button>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Período',
      render: (_, { startsAt, endsAt }) => (
        <span>
          {startsAt && endsAt
            ? `Desde ${moment(startsAt).format('DD/MM/YYYY')} hasta ${moment(
              endsAt,
            ).format('DD/MM/YYYY')}`
            : 'no definido'}
        </span>
      ),
      width: 180,
    },
    {
      title: 'Actualmente activa?',
      dataIndex: 'isActive',
      render: (_, record) => <span>{record.isActive ? 'Si' : 'No'}</span>,
      width: 100,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Button
              type="default"
              size="small"
              shape="circle"
              icon="edit"
              onClick={() => {
                setDisplayDiscountForm(record);
              }}
            />
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar el descuento?"
              onConfirm={() => destroyDiscount({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card loading={loading} title={header}>
      <BasicTable
        title={() => <Typography.Title level={4}>Descuentos</Typography.Title>}
        rowKey="id"
        columns={columns}
        nodes={business.discounts}
        bordered
        loading={destroyingDiscount}
      />

      <Modal visible={!!displayNewDiscountForm} footer={null} closable={false}>
        {!!displayNewDiscountForm && (
          <DiscountForm
            business={business}
            onClose={() => setDisplayNewDiscountForm(false)}
            saving={creatingDiscount}
            errors={createErrors}
            onSubmit={({
              title,
              image,
              description,
              startsAt,
              endsAt,
              isActive,
              branchIds,
            }) => createDiscount({
              variables: {
                attributes: {
                  businessId: Number(id),
                  title,
                  image,
                  description,
                  startsAt,
                  endsAt,
                  isActive,
                  branchIds: branchIds.map(e => Number(e)),
                },
              },
            })
            }
          />
        )}
      </Modal>

      <Modal visible={!!displayDiscountForm} footer={null} closable={false}>
        {!!displayDiscountForm && (
          <DiscountForm
            business={business}
            discount={displayDiscountForm}
            onClose={() => setDisplayDiscountForm(false)}
            saving={updatingDiscount}
            errors={updateErrors}
            onSubmit={({
              id: discountId,
              title,
              image,
              description,
              startsAt,
              endsAt,
              isActive,
              branchIds,
            }) => updateDiscount({
              variables: {
                id: Number(discountId),
                attributes: {
                  businessId: Number(id),
                  title,
                  image,
                  description,
                  startsAt: startsAt || null,
                  endsAt: endsAt || null,
                  isActive,
                  branchIds: branchIds.map(e => Number(e)),
                },
              },
            })
            }
          />
        )}
      </Modal>
    </Card>
  );
};
