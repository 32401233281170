import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Select,
  Typography,
  Row,
  Col,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const GET_ROLES = gql`
  query GetRoles {
    roles: getRoles {
      id
      name
      displayName
    }
  }
`;

export default ({
  user = {}, onSubmit, errors, saving, onClose,
}) => {
  const [userData, setUserData] = useState({});

  const { data: { roles = [] } = {}, loading: loadingRoles } = useQuery(
    GET_ROLES,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (user && user.id) {
      setUserData({
        ...user,
        role: user.defaultRole.name,
      });
    }
  }, [user]);

  function canSubmit() {
    return (
      userData.firstName && userData.lastName && userData.email && userData.role
    );
  }

  const firstNameErrors = errors && errors.find(e => e.key === 'firstName');
  const lastNameErrors = errors && errors.find(e => e.key === 'lastName');
  const emailErrors = errors && errors.find(e => e.key === 'email');
  const passwordErrors = errors && errors.find(e => e.key === 'password');
  const roleErrors = errors && errors.find(e => e.key === 'role');
  const isEditing = !!userData.id;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar usuario' : 'Crear usuario'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item
              label="Nombre"
              validateStatus={firstNameErrors ? 'error' : ''}
              help={firstNameErrors && firstNameErrors.messages.join(', ')}
            >
              <Input
                type="text"
                placeholder="Nombre"
                value={userData.firstName}
                onChange={e => setUserData({ ...userData, firstName: e.target.value })
                }
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Apellido"
              validateStatus={lastNameErrors ? 'error' : ''}
              help={lastNameErrors && lastNameErrors.messages.join(', ')}
            >
              <Input
                type="text"
                placeholder="Apellido"
                value={userData.lastName}
                onChange={e => setUserData({ ...userData, lastName: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={12}>
            <Form.Item
              label="E-mail"
              validateStatus={emailErrors ? 'error' : ''}
              help={emailErrors && emailErrors.messages.join(', ')}
            >
              <Input
                type="email"
                placeholder="E-mail"
                value={userData.email}
                onChange={e => setUserData({ ...userData, email: e.target.value })
                }
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>

          <Col xs={12}>
            <Form.Item
              label="Contraseña"
              validateStatus={passwordErrors ? 'error' : ''}
              help={passwordErrors && passwordErrors.messages.join(', ')}
            >
              <Input
                type="password"
                placeholder="Contraseña"
                value={userData.password}
                onChange={e => setUserData({ ...userData, password: e.target.value })
                }
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Rol"
              validateStatus={roleErrors ? 'error' : ''}
              help={roleErrors && roleErrors.messages.join(', ')}
            >
              <Select
                loading={loadingRoles}
                onChange={role => setUserData({ ...userData, role })}
                value={userData.role}
                placeholder="Seleccione rol"
                showSearch
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
              >
                {roles.map(role => (
                  <Select.Option key={role.name} value={role.name}>
                    {role.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit(userData)}
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
