import React from 'react';
import './App.css';
import { ApolloProvider } from '@apollo/react-hooks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';
import Routes from './router';
import ApolloClient from './config/ApolloClient';
import 'moment/locale/es';

moment.locale('es');

library.add(fal);
library.add(far);

export default () => (
  <BrowserRouter>
    <ApolloClient
      render={({ restored, client }) => (restored ? (
        <ApolloProvider client={client}>
          <Routes />
        </ApolloProvider>
      ) : (
        <div>Loading...</div>
      ))
      }
    />
  </BrowserRouter>
);
