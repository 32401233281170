/* eslint-disable no-console */

import { Component } from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { RestLink } from 'apollo-link-rest';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import apolloLogger from 'apollo-link-logger';
import { CachePersistor } from 'apollo-cache-persist';
import { createUploadLink } from 'apollo-upload-client';
import ActionCable from 'actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import * as camelCase from 'camelcase';

const AUTH_HEADERS = 'authHeaders';
const cache = new InMemoryCache();

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  debounce: 100,
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const authHeaders = JSON.parse(localStorage.getItem(AUTH_HEADERS));

  operation.setContext({
    headers: {
      ...authHeaders,
    },
  });

  return forward(operation);
});

const afterwareLink = new ApolloLink((operation, forward) => forward(operation).map((result) => {
  const { restResponses } = operation.getContext();

  if (!restResponses) {
    return result;
  }

  const authResponse = restResponses.find(res => res.headers.has('access-token'));

  if (authResponse) {
    const accessToken = authResponse.headers.get('access-token');

    if (accessToken) {
      const authHeaders = {
        'access-token': accessToken,
        client: authResponse.headers.get('client'),
        uid: authResponse.headers.get('uid'),
      };

      localStorage.setItem(AUTH_HEADERS, JSON.stringify(authHeaders));
    }
  }

  return result;
}));

const defaultState = {
  currentUser: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    __typename: 'User',
  },
};

const stateLink = withClientState({
  cache,
  defaults: defaultState,
  resolvers: {
    Mutation: {
      setCurrentUser: (_, { currentUser }, { cache: c }) => {
        const data = {
          currentUser,
        };

        c.writeData({ data });

        return currentUser;
      },
    },
  },
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  console.log(networkError);
  console.log(graphQLErrors);
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
});

const createActionCableLink = () => {
  const authHeaders = JSON.parse(localStorage.getItem(AUTH_HEADERS));
  let url = `${process.env.REACT_APP_API_ENDPOINT}/websocket`;

  if (authHeaders) {
    url += `?access-token=${authHeaders['access-token']}&client=${
      authHeaders.client
    }&uid=${authHeaders.uid}`;
  }

  const cable = ActionCable.createConsumer(url);

  return new ActionCableLink({ cable });
};

const hasSubscriptionOperation = ({ query: { definitions } }) => definitions.some(
  ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
);

const link = ApolloLink.split(
  hasSubscriptionOperation,
  createActionCableLink(),
  uploadLink,
);

const restLink = new RestLink({
  uri: `${process.env.REACT_APP_API_ENDPOINT}/`,
  fieldNameNormalizer: key => camelCase(key),
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    onErrorLink,
    apolloLogger,
    middlewareLink,
    afterwareLink,
    restLink,
    stateLink,
    link,
  ]),
});

client.onResetStore(() => {
  stateLink.writeDefaults();
  persistor.purge();
});

class Apollo extends Component {
  state = {
    client,
    restored: false,
  };

  async componentWillMount() {
    await persistor.restore();
    this.setState({ restored: true });
  }

  render() {
    const { render } = this.props;
    return render(this.state);
  }
}

export default Apollo;
