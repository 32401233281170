import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardComponent from './dashboard';
import BusinessesComponent from './businesses';
import BusinessShowComponent from './businesses/show';
import BusinessDiscountsComponent from './businesses/discounts';
import UsersComponent from './users';
import PlacesComponent from './places';
import EventTypesComponent from './eventTypes';
import BusRoutesComponent from './busRoutes';
import BusStopsComponent from './busRoutes/busStops';

export default ({ url }) => (
  <Switch>
    <Route exact path={url} component={DashboardComponent} />
    <Route exact path={`${url}businesses`} component={BusinessesComponent} />
    <Route
      exact
      path={`${url}businesses/:id`}
      component={BusinessShowComponent}
    />
    <Route
      exact
      path={`${url}businesses/:id/discounts`}
      component={BusinessDiscountsComponent}
    />
    <Route exact path={`${url}users`} component={UsersComponent} />
    <Route exact path={`${url}places`} component={PlacesComponent} />
    <Route exact path={`${url}events`} component={EventTypesComponent} />
    <Route exact path={`${url}bus_routes`} component={BusRoutesComponent} />
    <Route
      exact
      path={`${url}bus_routes/:id/bus_stops`}
      component={BusStopsComponent}
    />
  </Switch>
);
