import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { usePrevious } from 'react-hanger';
import {
  Layout, Menu, Icon, Row, Col,
} from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import { AbilityBuilder } from '@casl/ability';
import { VALIDATE_TOKEN } from '../../queries';
import Loading from '../shared/loading';
import StyleHolder from './index.style';
import Router from './router';
import AbilityContext from './abilityContext';

const { Header, Sider, Content } = Layout;

export default ({ history, pathname }) => {
  const [drawerCollapsed, setDrawerCollapsed] = useState(true);
  const width = useWindowWidth({ debounceOptions: { wait: 1000 } });
  const { url } = useRouteMatch();
  const isMobile = width < 480;

  const { data: { result: { user } = {} } = {}, loading } = useQuery(
    VALIDATE_TOKEN,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const wasLoading = usePrevious(loading);

  useEffect(() => {
    if (wasLoading && !loading && !user) {
      history.push('/login');
    }
  }, [loading]);

  if (wasLoading === undefined && loading) {
    return <Loading />;
  }

  function handleLogout() {
    localStorage.clear();
    history.push('/login');
  }

  const ability = AbilityBuilder.define((can, cannot) => {
    if (!user) {
      return;
    }

    user.permissions.forEach((permission) => {
      if (permission.can) {
        can(permission.actions, permission.subjects, permission.conditions);
      } else {
        cannot(permission.actions, permission.subjects, permission.conditions);
      }
    });
  });

  return (
    <AbilityContext.Provider value={ability}>
      <StyleHolder>
        <Layout style={{ minHeight: '100vh' }}>
          <Header style={{ background: '#e30066', padding: '0 15px' }}>
            <Row type="flex" align="middle">
              <Col xs={4}>
                <Icon
                  style={{ fontSize: 24, color: 'white', marginLeft: '14px' }}
                  className="trigger"
                  type="menu-fold"
                  onClick={() => setDrawerCollapsed(!drawerCollapsed)}
                />
              </Col>
              <Col xs={16} style={{ textAlign: 'center' }}>
                <Link to={url} style={{ color: 'white' }}>
                  <strong>Go City Tour</strong>
                </Link>
              </Col>
              <Col xs={4} />
            </Row>
          </Header>

          <Layout>
            <Sider
              width={isMobile ? width : 200}
              trigger={null}
              collapsible
              collapsedWidth={isMobile ? 0 : 80}
              collapsed={drawerCollapsed}
              style={
                isMobile
                  ? {
                    position: 'absolute',
                    zIndex: 999,
                    height: '100vh',
                  }
                  : {}
              }
            >
              <Menu theme="dark" mode="inline" selectedKeys={[pathname]}>
                <Menu.Item key={url}>
                  <Link to={url}>
                    <Icon type="home" />
                    <span>Principal</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key={`${url}businesses`}>
                  <Link to={`${url}businesses`}>
                    <Icon type="shop" />
                    <span>Comercios</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key={`${url}places`}>
                  <Link to={`${url}places`}>
                    <Icon type="environment" />
                    <span>Puntos de interés</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key={`${url}events`}>
                  <Link to={`${url}events`}>
                    <Icon type="calendar" />
                    <span>Eventos</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key={`${url}bus_routes`}>
                  <Link to={`${url}bus_routes`}>
                    <Icon type="pushpin" />
                    <span>Rutas</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key={`${url}users`}>
                  <Link to={`${url}users`}>
                    <Icon type="team" />
                    <span>Usuarios</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="logout" onClick={handleLogout}>
                  <Icon type="logout" />
                  <span>Salir</span>
                </Menu.Item>
              </Menu>
            </Sider>
            <Content style={{ padding: 20 }}>
              <Router url={url} />
            </Content>
          </Layout>
        </Layout>
      </StyleHolder>
    </AbilityContext.Provider>
  );
};
