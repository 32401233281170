import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Typography,
  Row,
  Col,
  notification,
  Select,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import GoogleMapReact from 'google-map-react';

const GET_STATES = gql`
  query GetStates {
    states: getStates {
      id
      name
    }
  }
`;

const GET_BRANCH_MANAGERS = gql`
  query GetBranchManagers {
    users: getUsers(q: { rolesNameEq: "branch_manager" }) {
      branchManagers: nodes {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export default ({
  branch = {}, onSubmit, errors, saving, onClose,
}) => {
  const [branchData, setBranchData] = useState({});
  const [center, setCenter] = useState({
    lat: -32.8833352,
    lng: -68.8585191,
  });
  const [autocompleteData, setAutocompleteData] = useState();
  const [zoom, setZoom] = useState(12);

  const { data: { states = [] } = {} } = useQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: { users = {} } = {},
    loading: loadingBranchManagers,
  } = useQuery(GET_BRANCH_MANAGERS, {
    fetchPolicy: 'cache-and-network',
  });

  const { branchManagers = [] } = users;

  useEffect(() => {
    if (branch && branch.id) {
      setBranchData(branch);
      setZoom(17);
      setCenter({
        lat: Number(branch.latitude),
        lng: Number(branch.longitude),
      });
    }
  }, [branch]);

  useEffect(() => {
    if (!autocompleteData) {
      return;
    }

    const { place_id: placeId, description } = autocompleteData;

    setAutocompleteData(description);

    geocodeByPlaceId(placeId).then((results) => {
      const firstResult = results[0];
      const { address_components: addressComponents } = firstResult;

      const stateComponent = addressComponents.find(e => e.types.includes('administrative_area_level_1'));

      if (!stateComponent) {
        return;
      }

      const { long_name: stateName } = stateComponent;

      const state = states.find(
        s => s.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          === stateName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
      );

      if (!state) {
        notification.error({
          message: 'Error',
          description:
            'No se ha podido encontrar la dirección indicada en el mapa',
        });
        return;
      }

      const latitude = firstResult.geometry.location.lat();
      const longitude = firstResult.geometry.location.lng();

      setBranchData({
        ...branchData,
        stateId: state.id,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        address: description,
      });

      setZoom(17);
      setCenter({
        lat: Number(latitude),
        lng: Number(longitude),
      });
    });
  }, [autocompleteData]);

  function canSubmit() {
    return (
      branchData.name
      && branchData.address
      && branchData.stateId
      && branchData.branchManagerId
    );
  }

  const nameErrors = errors && errors.find(e => e.key === 'name');
  const addressErrors = errors && errors.find(e => e.key === 'address');
  const isEditing = !!branch.id;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar sucursal' : 'Crear sucursal'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Nombre"
              validateStatus={nameErrors ? 'error' : ''}
              help={nameErrors && nameErrors.messages.join(', ')}
            >
              <Input
                autoFocus
                type="text"
                placeholder="Nombre"
                value={branchData.name}
                onChange={e => setBranchData({
                  ...branchData,
                  name: e.target.value,
                })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Dirección"
              validateStatus={addressErrors ? 'error' : ''}
              help={addressErrors && addressErrors.messages.join(', ')}
            >
              <GooglePlacesAutocomplete
                placeholder="Escriba una dirección"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['ar'],
                  },
                }}
                onSelect={setAutocompleteData}
                onChange={e => setBranchData({
                  ...branchData,
                  address: e.target.value,
                })
                }
                initialValue={branchData.address}
                renderInput={props => (
                  <Input type="text" placeholder="Dirección" {...props} />
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <div style={{ height: '350px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
              center={center}
              zoom={zoom}
              onClick={({ lat, lng }) => {
                setBranchData({
                  ...branchData,
                  latitude: lat.toString(),
                  longitude: lng.toString(),
                });
              }}
            >
              {branchData.latitude && branchData.longitude && (
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="3x"
                  style={{
                    color: '#e30066',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  lat={Number(branchData.latitude)}
                  lng={Number(branchData.longitude)}
                />
              )}
            </GoogleMapReact>
          </div>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item label="Descripción">
              <Input.TextArea
                rows={4}
                placeholder="Descripción de la sucursal"
                value={branchData.description}
                onChange={e => setBranchData({
                  ...branchData,
                  description: e.target.value,
                })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item label="Administrador de sucursal">
              <Select
                loading={loadingBranchManagers}
                onChange={(branchManagerId) => {
                  setBranchData({ ...branchData, branchManagerId });
                }}
                value={branchData.branchManagerId}
                busRouteholder="Seleccione administrador de sucursal"
                showSearch
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
              >
                {branchManagers.map(bm => (
                  <Select.Option key={bm.id} value={bm.id}>
                    {`${bm.firstName} ${bm.lastName} (${bm.email})`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit(branchData)}
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
