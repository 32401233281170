export default [
  {
    locale: 'es',
    name: 'Español',
  },
  {
    locale: 'en',
    name: 'Inglés',
  },
  {
    locale: 'pt',
    name: 'Portugués',
  },
];
