import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import moment from 'moment';
import {
  Row, Col, Button, Card, Popconfirm, notification, Modal,
} from 'antd';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SearchDropdown from '../../shared/searchDropdown';
import BasicTable from '../../shared/basicTable';
import { eventItemFragments } from '../../../fragments';
import EventItemForm from './form';

const GET_EVENT_ITEMS = gql`
  query GetEventItems($q: EventItemFilter!, $pagination: PaginationFilter) {
    eventItems: getEventItems(q: $q, pagination: $pagination) {
      nodes {
        ...EventItemFields
      }
      totalCount
      totalPageCount
    }
  }
  ${eventItemFragments.eventItem}
`;

const CREATE_EVENT_ITEM = gql`
  mutation CreateEventItem($attributes: EventItemAttributes!) {
    result: createEventItem(attributes: $attributes) {
      eventItem {
        ...EventItemFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${eventItemFragments.eventItem}
`;

const UPDATE_EVENT_ITEM = gql`
  mutation UpdateEventItem($id: Int!, $attributes: EventItemAttributes!) {
    result: updateEventItem(id: $id, attributes: $attributes) {
      eventItem {
        ...EventItemFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${eventItemFragments.eventItem}
`;

const DESTROY_EVENT_ITEM = gql`
  mutation DestroyEventItem($id: Int!) {
    result: destroyEventItem(id: $id) {
      eventItem {
        ...EventItemFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${eventItemFragments.eventItem}
`;

export default ({ history }) => {
  const [query, setQuery] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [eventItemFormData, setEventItemFormData] = useState();

  const { data: { eventItems = [] } = {}, loading } = useQuery(
    GET_EVENT_ITEMS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        q: query,
        pagination: {
          page: pagination.current,
          perPage: pagination.pageSize,
        },
      },
    },
  );

  const [
    createEventItem,
    { data: { result: createResult = {} } = {}, loading: creatingEventItem },
  ] = useMutation(CREATE_EVENT_ITEM, {
    update: (client, { data }) => {
      if (data.result.eventItem) {
        notification.success({
          message: 'Bien!',
          description: 'Evento creado correctamente.',
        });

        setEventItemFormData(null);

        const previousData = client.readQuery({
          query: GET_EVENT_ITEMS,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
        });

        client.writeQuery({
          query: GET_EVENT_ITEMS,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
          data: {
            ...previousData,
            eventItems: {
              ...eventItems,
              nodes: [...previousData.eventItems.nodes, data.result.eventItem],
            },
          },
        });
      }
    },
  });

  const [
    updateEventItem,
    { data: { result: updateResult = {} } = {}, loading: updatingEventItem },
  ] = useMutation(UPDATE_EVENT_ITEM, {
    update: (_, { data }) => {
      if (data.result.eventItem) {
        notification.success({
          message: 'Bien!',
          description: 'Evento actualizado correctamente.',
        });

        setEventItemFormData(null);
      }
    },
  });

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const [destroyEventItem, { loading: destroyingEventItem }] = useMutation(
    DESTROY_EVENT_ITEM,
    {
      update: (client, { data }) => {
        if (data.result.eventItem) {
          notification.success({
            message: 'Bien!',
            description: 'Evento eliminado correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_EVENT_ITEMS,
            variables: {
              q: query,
              pagination: {
                page: pagination.current,
                perPage: pagination.pageSize,
              },
            },
          });

          client.writeQuery({
            query: GET_EVENT_ITEMS,
            variables: {
              q: query,
              pagination: {
                page: pagination.current,
                perPage: pagination.pageSize,
              },
            },
            data: {
              ...previousData,
              eventItems: {
                ...eventItems,
                nodes: previousData.eventItems.nodes.filter(
                  u => u.id !== data.result.eventItem.id,
                ),
              },
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    const { location } = history;
    const searchObject = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { page, ...queryObject } = searchObject;

    setQuery(queryObject);

    if (queryObject.s) {
      const [columnKey, order] = queryObject.s.split(' ');

      setSortedInfo({
        columnKey,
        order,
      });
    }

    if (page) {
      setPagination({
        current: Number(page),
        pageSize: 30,
      });
    }
  }, [history.location.search]);

  function handleTableChange(tablePagination: pagination, q) {
    history.push({
      search: qs.stringify({ ...q, page: tablePagination.current }),
    });
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'nameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.name ? [query.name] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Fecha y hora',
      dataIndex: 'startsAt',
      key: 'startsAt',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'startsAt' && sortedInfo.order,
      render: (_, record) => (
        <span>{moment(record.startsAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
      width: 150,
    },
    {
      title: 'Provincia',
      dataIndex: 'state.name',
      key: 'stateNameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'stateNameCont' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.stateNameCont ? [query.stateNameCont] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Button
              type="default"
              size="small"
              shape="circle"
              icon="edit"
              onClick={() => setEventItemFormData(record)}
            />
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar el evento?"
              onConfirm={() => destroyEventItem({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        Eventos
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="default" onClick={() => setEventItemFormData({})}>
          Nuevo evento
        </Button>
      </Col>
    </Row>
  );

  const { nodes, totalCount } = eventItems;

  return (
    <Card title={header}>
      <BasicTable
        rowKey="id"
        columns={columns}
        nodes={nodes}
        loading={loading || destroyingEventItem}
        onHandleTableChange={handleTableChange}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
      />

      {eventItemFormData && eventItemFormData.id && (
        <Modal visible closable={false} footer={null}>
          <EventItemForm
            eventItem={eventItemFormData}
            saving={updatingEventItem}
            errors={updateErrors}
            onClose={() => setEventItemFormData(null)}
            onSubmit={({
              id,
              translationsAttributes,
              startsAt,
              latitude,
              longitude,
              address,
              stateId,
              image,
            }) => updateEventItem({
              variables: {
                id: Number(id),
                attributes: {
                  translationsAttributes,
                  startsAt,
                  latitude,
                  longitude,
                  address,
                  stateId,
                  image,
                },
              },
            })
            }
          />
        </Modal>
      )}

      {eventItemFormData && !eventItemFormData.id && (
        <Modal visible closable={false} footer={null}>
          <EventItemForm
            eventItem={eventItemFormData}
            saving={creatingEventItem}
            errors={createErrors}
            onClose={() => setEventItemFormData(null)}
            onSubmit={({
              translationsAttributes,
              startsAt,
              latitude,
              longitude,
              address,
              stateId,
              image,
            }) => createEventItem({
              variables: {
                attributes: {
                  translationsAttributes,
                  startsAt,
                  latitude,
                  longitude,
                  address,
                  stateId,
                  image,
                },
              },
            })
            }
          />
        </Modal>
      )}
    </Card>
  );
};
