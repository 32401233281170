import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Typography,
  Row,
  Col,
  notification,
  Upload,
  Icon,
  Tabs,
  DatePicker,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import 'react-google-places-autocomplete/dist/assets/index.css';
import GoogleMapReact from 'google-map-react';
import moment from 'moment';
import getBase64 from '../../shared/getBase64';
import locales from '../../shared/locales';

const GET_STATES = gql`
  query GetStates {
    states: getStates {
      id
      name
    }
  }
`;

export default ({
  eventItem = {}, onSubmit, errors, saving, onClose,
}) => {
  const [eventItemData, setEventItemData] = useState({
    translationsAttributes: locales.map(l => ({ locale: l.locale })),
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  const [center, setCenter] = useState({
    lat: -32.8833352,
    lng: -68.8585191,
  });
  const [autocompleteData, setAutocompleteData] = useState();
  const [zoom, setZoom] = useState(12);

  const { data: { states = [] } = {} } = useQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (eventItem && eventItem.id) {
      setEventItemData({
        ...eventItem,
        translationsAttributes: eventItem.translations.map(t => ({
          id: t.id,
          locale: t.locale,
          name: t.name,
          description: t.description,
        })),
      });
      setZoom(17);
      setCenter({
        lat: Number(eventItem.latitude),
        lng: Number(eventItem.longitude),
      });

      if (eventItem.imageUrl) {
        setImagePreviewUrl(
          `${process.env.REACT_APP_API_ENDPOINT}${eventItem.imageUrl}`,
        );
      }
    }
  }, [eventItem]);

  useEffect(() => {
    if (!autocompleteData) {
      return;
    }

    const { place_id: placeId, description } = autocompleteData;

    setAutocompleteData(description);

    geocodeByPlaceId(placeId).then((results) => {
      const firstResult = results[0];
      const { address_components: addressComponents } = firstResult;

      const stateComponent = addressComponents.find(e => e.types.includes('administrative_area_level_1'));

      if (!stateComponent) {
        return;
      }

      const { long_name: stateName } = stateComponent;

      const state = states.find(
        s => s.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          === stateName
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
      );

      if (!state) {
        notification.error({
          message: 'Error',
          description:
            'No se ha podido encontrar la dirección indicada en el mapa',
        });
        return;
      }

      const latitude = firstResult.geometry.location.lat();
      const longitude = firstResult.geometry.location.lng();

      setEventItemData({
        ...eventItemData,
        stateId: state.id,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        address: description,
      });

      setZoom(17);
      setCenter({
        lat: Number(latitude),
        lng: Number(longitude),
      });
    });
  }, [autocompleteData]);

  function canSubmit() {
    return (
      eventItemData.startsAt
      && eventItemData.address
      && eventItemData.stateId
      && eventItemData.translationsAttributes.some(e => !!e.name)
    );
  }

  function handleChangeImage(info) {
    getBase64(info.file, (imageUrl) => {
      setEventItemData({
        ...eventItemData,
        image: info.file,
      });

      setImagePreviewUrl(imageUrl);
    });
  }

  const nameErrors = errors && errors.find(e => e.key === 'name');
  const addressErrors = errors && errors.find(e => e.key === 'address');
  const descriptionErrors = errors && errors.find(e => e.key === 'description');
  const isEditing = !!eventItemData.id;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar evento' : 'Crear evento'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row gutter={16}>
          <Tabs defaultActiveKey="es" type="card">
            {eventItemData.translationsAttributes.map((translationAttribute) => {
              const currentLocale = locales.find(
                l => translationAttribute.locale === l.locale,
              );
              return (
                <Tabs.TabPane
                  tab={currentLocale.name}
                  key={translationAttribute.locale}
                >
                  <Col xs={24}>
                    <Form.Item
                      label={`Nombre en ${currentLocale.name}`}
                      validateStatus={nameErrors ? 'error' : ''}
                      help={nameErrors && nameErrors.messages.join(', ')}
                    >
                      <Input
                        type="text"
                        placeholder={`Nombre en ${currentLocale.name}`}
                        value={
                          eventItemData.translationsAttributes.find(
                            ta => ta.locale === translationAttribute.locale,
                          ).name
                        }
                        onChange={e => setEventItemData({
                          ...eventItemData,
                          translationsAttributes: eventItemData.translationsAttributes.map(
                            (ta) => {
                              if (ta.locale === translationAttribute.locale) {
                                return {
                                  ...ta,
                                  name: e.target.value,
                                };
                              }

                              return ta;
                            },
                          ),
                        })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item label="Fecha y hora de inicio">
              <DatePicker
                format="DD/MM/YYYY HH:mm"
                showTime={{ format: 'HH:mm' }}
                value={
                  eventItemData && eventItemData.startsAt
                    ? moment(eventItemData.startsAt)
                    : null
                }
                style={{ width: '100%' }}
                onChange={startsAt => setEventItemData({ ...eventItemData, startsAt })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Dirección"
              validateStatus={addressErrors ? 'error' : ''}
              help={addressErrors && addressErrors.messages.join(', ')}
            >
              <GooglePlacesAutocomplete
                placeholder="Escriba una dirección"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['ar'],
                  },
                }}
                onSelect={setAutocompleteData}
                onChange={e => setEventItemData({
                  ...eventItemData,
                  address: e.target.value,
                })
                }
                initialValue={eventItemData.address}
                renderInput={props => (
                  <Input type="text" placeholder="Dirección" {...props} />
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <div style={{ height: '350px', width: '100%' }}>
            <GoogleMapReact
              options={{ scrollwheel: false }}
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
              center={center}
              zoom={zoom}
              onClick={({ lat, lng }) => {
                setEventItemData({
                  ...eventItemData,
                  latitude: lat.toString(),
                  longitude: lng.toString(),
                });
              }}
            >
              {eventItemData.latitude && eventItemData.longitude && (
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="3x"
                  style={{
                    color: '#e30066',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  lat={Number(eventItemData.latitude)}
                  lng={Number(eventItemData.longitude)}
                />
              )}
            </GoogleMapReact>
          </div>
        </Row>

        <Row style={{ marginTop: 10 }}>
          <Tabs defaultActiveKey="es" type="card">
            {eventItemData.translationsAttributes.map((translationAttribute) => {
              const currentLocale = locales.find(
                l => translationAttribute.locale === l.locale,
              );

              return (
                <Tabs.TabPane
                  tab={currentLocale.name}
                  key={translationAttribute.locale}
                >
                  <Col xs={24}>
                    <Form.Item
                      label={`Descripción en ${currentLocale.name}`}
                      validateStatus={descriptionErrors ? 'error' : ''}
                      help={
                        descriptionErrors
                        && descriptionErrors.messages.join(', ')
                      }
                    >
                      <Input.TextArea
                        rows={8}
                        placeholder={`Descripción en ${currentLocale.name}`}
                        value={
                          eventItemData.translationsAttributes.find(
                            ta => ta.locale === translationAttribute.locale,
                          ).description
                        }
                        onChange={e => setEventItemData({
                          ...eventItemData,
                          translationsAttributes: eventItemData.translationsAttributes.map(
                            (ta) => {
                              if (ta.locale === translationAttribute.locale) {
                                return {
                                  ...ta,
                                  description: e.target.value,
                                };
                              }

                              return ta;
                            },
                          ),
                        })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item label="Seleccionar imagen">
              <Upload
                accept="image/x-png,image/jpeg"
                name="image"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={handleChangeImage}
                style={{ cursor: 'pointer', width: '100%' }}
              >
                {imagePreviewUrl ? (
                  <div>
                    <img
                      src={imagePreviewUrl}
                      alt="logo"
                      style={{ height: 150 }}
                    />
                    <Button
                      type="danger"
                      style={{ position: 'absolute', right: 5 }}
                      icon="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEventItemData({
                          ...eventItemData,
                          image: null,
                        });
                        setImagePreviewUrl(null);
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">Seleccionar imagen</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit(eventItemData)}
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
