import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Typography,
  Row,
  Col,
  Upload,
  Icon,
  Select,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import getBase64 from '../../shared/getBase64';

const GET_PLACE_CATEGORIES = gql`
  query GetPlaceCategories {
    placeCategories: getPlaceCategories {
      id
      name
      iconPath
    }
  }
`;

export default ({
  business = {}, onSubmit, errors, saving, onClose,
}) => {
  const [businessData, setBusinessData] = useState({});
  const [logoPreviewUrl, setLogoPreviewUrl] = useState();

  const {
    data: { placeCategories = [] } = {},
    loading: loadingPlaceCategories,
  } = useQuery(GET_PLACE_CATEGORIES, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (business && business.id) {
      setBusinessData(business);

      if (business.logoUrl) {
        setLogoPreviewUrl(
          `${process.env.REACT_APP_API_ENDPOINT}${business.logoUrl}`,
        );
      }
    }
  }, [business]);

  function canSubmit() {
    return businessData.name;
  }

  function handleChange(info) {
    getBase64(info.file, (imageUrl) => {
      setBusinessData({
        ...businessData,
        logo: info.file,
      });

      setLogoPreviewUrl(imageUrl);
    });
  }

  const nameErrors = errors && errors.find(e => e.key === 'name');
  const isEditing = !!businessData.id;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar comercio' : 'Crear comercio'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Nombre"
              validateStatus={nameErrors ? 'error' : ''}
              help={nameErrors && nameErrors.messages.join(', ')}
            >
              <Input
                autoFocus
                type="text"
                placeholder="Nombre"
                value={businessData.name}
                onChange={e => setBusinessData({
                  ...businessData,
                  name: e.target.value,
                })
                }
              />
            </Form.Item>

            <Form.Item label="Categoría">
              <Select
                loading={loadingPlaceCategories}
                onChange={placeCategoryId => setBusinessData({ ...businessData, placeCategoryId })
                }
                value={businessData.placeCategoryId}
                placeholder="Seleccione categoría"
                showSearch
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
              >
                {placeCategories.map(category => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex">
          <Col xs={24} style={{ textAlign: 'center' }}>
            <Upload
              accept="image/x-png,image/jpeg"
              name="logo"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={handleChange}
              style={{ cursor: 'pointer' }}
            >
              {logoPreviewUrl ? (
                <img src={logoPreviewUrl} alt="logo" style={{ height: 150 }} />
              ) : (
                <div>
                  <Icon type="plus" />
                  <div className="ant-upload-text">Seleccionar imagen</div>
                </div>
              )}
            </Upload>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit(businessData)}
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
