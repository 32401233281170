import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import moment from 'moment';
import {
  Row, Col, Button, Card, Popconfirm, notification, Modal,
} from 'antd';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SearchDropdown from '../../shared/searchDropdown';
import BasicTable from '../../shared/basicTable';
import { userFragments } from '../../../fragments';
import UserForm from './form';

const GET_USERS = gql`
  query GetUsers($q: UserFilter!, $pagination: PaginationFilter) {
    users: getUsers(q: $q, pagination: $pagination) {
      nodes {
        ...UserFields
      }
      totalCount
      totalPageCount
    }
  }
  ${userFragments.user}
`;

const CREATE_USER = gql`
  mutation CreateUser($attributes: UserAttributes!, $role: String) {
    result: createUser(attributes: $attributes, role: $role) {
      user {
        ...UserFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${userFragments.user}
`;

const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $attributes: UserAttributes!, $role: String) {
    result: updateUser(id: $id, attributes: $attributes, role: $role) {
      user {
        ...UserFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${userFragments.user}
`;

const DESTROY_USER = gql`
  mutation DestroyUser($id: Int!) {
    result: destroyUser(id: $id) {
      user {
        ...UserFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${userFragments.user}
`;

export default ({ history }) => {
  const [query, setQuery] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [userFormData, setUserFormData] = useState();

  const { data: { users = [] } = {}, loading } = useQuery(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      q: query,
      pagination: {
        page: pagination.current,
        perPage: pagination.pageSize,
      },
    },
  });

  const [
    createUser,
    { data: { result: createResult = {} } = {}, loading: creatingUser },
  ] = useMutation(CREATE_USER, {
    update: (client, { data }) => {
      if (data.result.user) {
        notification.success({
          message: 'Bien!',
          description: 'Usuario creado correctamente.',
        });

        setUserFormData(null);

        const previousData = client.readQuery({
          query: GET_USERS,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
        });

        client.writeQuery({
          query: GET_USERS,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
          data: {
            ...previousData,
            users: {
              ...users,
              nodes: [...previousData.users.nodes, data.result.user],
            },
          },
        });
      }
    },
  });

  const [
    updateUser,
    { data: { result: updateResult = {} } = {}, loading: updatingUser },
  ] = useMutation(UPDATE_USER, {
    update: (_, { data }) => {
      if (data.result.user) {
        notification.success({
          message: 'Bien!',
          description: 'Usuario actualizado correctamente.',
        });

        setUserFormData(null);
      }
    },
  });

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const [destroyUser, { loading: destroyingUser }] = useMutation(DESTROY_USER, {
    update: (client, { data }) => {
      if (data.result.user) {
        notification.success({
          message: 'Bien!',
          description: 'Usuario eliminado correctamente.',
        });

        const previousData = client.readQuery({
          query: GET_USERS,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
        });

        client.writeQuery({
          query: GET_USERS,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
          data: {
            ...previousData,
            users: {
              ...users,
              nodes: previousData.users.nodes.filter(
                u => u.id !== data.result.user.id,
              ),
            },
          },
        });
      }
    },
  });

  useEffect(() => {
    const { location } = history;
    const searchObject = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { page, ...queryObject } = searchObject;

    setQuery(queryObject);

    if (queryObject.s) {
      const [columnKey, order] = queryObject.s.split(' ');

      setSortedInfo({
        columnKey,
        order,
      });
    }

    if (page) {
      setPagination({
        current: Number(page),
        pageSize: 30,
      });
    }
  }, [history.location.search]);

  function handleTableChange(tablePagination: pagination, q) {
    history.push({
      search: qs.stringify({ ...q, page: tablePagination.current }),
    });
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'firstName',
      key: 'firstNameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'firstName' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.firstNameCont ? [query.firstNameCont] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Apellido',
      dataIndex: 'lastName',
      key: 'lastNameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'lastName' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.lastNameCont ? [query.lastNameCont] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'emailCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'email' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.emailCont ? [query.emailCont] : [],
      render: text => <span>{text}</span>,
      width: 200,
    },
    {
      title: 'Rol',
      dataIndex: 'defaultRole.displayName',
      key: 'rolesDisplayNameCont',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.rolesDisplayNameCont
        ? [query.rolesDisplayNameCont]
        : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          {record.defaultRole && (
            <Col xs={3}>
              <Button
                type="default"
                size="small"
                shape="circle"
                icon="edit"
                onClick={() => setUserFormData(record)}
              />
            </Col>
          )}

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar el usuario?"
              onConfirm={() => destroyUser({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        Usuarios
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="default" onClick={() => setUserFormData({})}>
          Nuevo usuario
        </Button>
      </Col>
    </Row>
  );

  const { nodes, totalCount } = users;

  return (
    <Card title={header}>
      <BasicTable
        rowKey="id"
        columns={columns}
        nodes={nodes}
        loading={loading || destroyingUser}
        onHandleTableChange={handleTableChange}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
      />

      {userFormData && userFormData.id && (
        <Modal visible closable={false} footer={null}>
          <UserForm
            user={userFormData}
            saving={updatingUser}
            errors={updateErrors}
            onClose={() => setUserFormData(null)}
            onSubmit={({
              id,
              firstName,
              lastName,
              email,
              password,
              role,
            }) => updateUser({
              variables: {
                id: Number(id),
                attributes: {
                  firstName,
                  lastName,
                  email,
                  password,
                },
                role,
              },
            })
            }
          />
        </Modal>
      )}

      {userFormData && !userFormData.id && (
        <Modal visible closable={false} footer={null}>
          <UserForm
            user={userFormData}
            saving={creatingUser}
            errors={createErrors}
            onClose={() => setUserFormData(null)}
            onSubmit={({
              firstName,
              lastName,
              email,
              password,
              role,
            }) => createUser({
              variables: {
                attributes: {
                  firstName,
                  lastName,
                  email,
                  password,
                },
                role,
              },
            })
            }
          />
        </Modal>
      )}
    </Card>
  );
};
