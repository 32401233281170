import React, { useState, useEffect } from 'react';
import {
  Input, Button, Col, Row, Typography, Form,
} from 'antd';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { usePrevious } from 'react-hanger';
import StyleWrapper from './index.style';
import { VALIDATE_TOKEN } from '../../queries';
import Loading from '../shared/loading';

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    result: login(input: { email: $email, password: $password })
      @rest(type: "User", path: "auth/sign_in/", method: "POST") {
      user: data @type(name: "User") {
        id
        firstName
        lastName
        email
      }
      errors @type(name: "errors") {
        key
        messages
      }
    }
  }
`;

export default ({ history }) => {
  const {
    data: { result: { user: currentUser } = {} } = {},
    loading,
  } = useQuery(VALIDATE_TOKEN, {
    fetchPolicy: 'cache-and-network',
  });

  const [
    login,
    { data: { result: { user, errors = [] } = {} } = {}, loading: logging },
  ] = useMutation(LOGIN);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  function handleLogin() {
    login({
      variables: {
        email,
        password,
      },
    });
  }

  // Check if user already signed in.
  const wasLoading = usePrevious(loading);

  useEffect(() => {
    if (wasLoading && !loading && currentUser) {
      history.push('/');
    }
  }, [loading]);

  useEffect(() => {
    if (user && user.id) {
      history.push('/');
    }
  }, [user]);

  if (loading) {
    return <Loading />;
  }

  return (
    <StyleWrapper>
      <Row className="loginHolder" type="flex" justify="center" align="middle">
        <Col xs={0} sm={0} md={15} lg={15} />
        <Col xs={23} sm={18} md={8} lg={8} className="loginForm">
          <Typography.Title level={2}>Go City Tour</Typography.Title>
          <div className="loginButtonContainer">
            <Form style={{ width: '85%' }}>
              <Form.Item
                validateStatus={errors.length > 0 ? 'error' : ''}
                style={{ marginBottom: 0 }}
              >
                <Input
                  type="email"
                  placeholder="E-mail"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyDown={e => e.key === 'Enter' && handleLogin()}
                  autoComplete="new-password"
                />
              </Form.Item>

              <Form.Item
                validateStatus={errors.length > 0 ? 'error' : ''}
                help={errors.length > 0 && errors[0].messages}
              >
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onKeyDown={e => e.key === 'Enter' && handleLogin()}
                  autoComplete="new-password"
                />
              </Form.Item>
            </Form>

            <Button
              className="loginButton"
              loading={logging}
              onClick={() => handleLogin()}
            >
              Ingresar
            </Button>
          </div>
        </Col>
        <Col xs={0} sm={0} md={1} lg={1} />
      </Row>
    </StyleWrapper>
  );
};
