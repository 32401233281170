import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Card,
  Row,
  Col,
  Button,
  Skeleton,
  Typography,
  Modal,
  notification,
  Popconfirm,
} from 'antd';
import { busRouteFragments, busStopFragments } from '../../../../fragments';
import BasicTable from '../../../shared/basicTable';
import BusStopForm from './form';

const GET_BUS_ROUTE = gql`
  query GetBusRoute($id: Int!) {
    busRoute: getBusRoute(id: $id) {
      ...BusRouteFields
      busStops {
        ...BusStopFields
      }
    }
  }
  ${busRouteFragments.busRoute}
  ${busStopFragments.busStop}
`;

const CREATE_BUS_STOP = gql`
  mutation CreateBusStop($attributes: BusStopAttributes!) {
    result: createBusStop(attributes: $attributes) {
      busStop {
        ...BusStopFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${busStopFragments.busStop}
`;

const UPDATE_BUS_STOP = gql`
  mutation UpdateBusStop($id: Int!, $attributes: BusStopAttributes!) {
    result: updateBusStop(id: $id, attributes: $attributes) {
      busStop {
        ...BusStopFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${busStopFragments.busStop}
`;

const DESTROY_BUS_STOP = gql`
  mutation DestroyBusStop($id: Int!) {
    result: destroyBusStop(id: $id) {
      busStop {
        ...BusStopFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${busStopFragments.busStop}
`;

export default () => {
  const [displayNewBusStopForm, setDisplayNewBusStopForm] = useState(false);
  const [displayBusStopForm, setDisplayBusStopForm] = useState();
  const { id } = useParams();

  const { data: { busRoute = {} } = {}, loading } = useQuery(GET_BUS_ROUTE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(id),
    },
  });

  const [
    createBusStop,
    { data: { result: createResult = {} } = {}, loading: creatingBusStop },
  ] = useMutation(CREATE_BUS_STOP, {
    update: (client, { data }) => {
      if (data.result.busStop) {
        notification.success({
          message: 'Bien!',
          description: 'Parada creada correctamente.',
        });

        setDisplayNewBusStopForm(false);

        const previousData = client.readQuery({
          query: GET_BUS_ROUTE,
          variables: {
            id: Number(id),
          },
        });

        client.writeQuery({
          query: GET_BUS_ROUTE,
          variables: {
            id: Number(id),
          },
          data: {
            ...previousData,
            busRoute: {
              ...previousData.busRoute,
              busStops: [
                ...previousData.busRoute.busStops,
                data.result.busStop,
              ],
            },
          },
        });
      }
    },
  });

  const [
    updateBusStop,
    { data: { result: updateResult = {} } = {}, loading: updatingBusStop },
  ] = useMutation(UPDATE_BUS_STOP, {
    update: (_, { data }) => {
      if (data.result.busStop) {
        notification.success({
          message: 'Bien!',
          description: 'Parada actualizada correctamente.',
        });

        setDisplayBusStopForm(false);
      }
    },
  });

  const [destroyBusStop, { loading: destroyingBusStop }] = useMutation(
    DESTROY_BUS_STOP,
    {
      update: (client, { data }) => {
        if (data.result.busStop) {
          notification.success({
            message: 'Bien!',
            description: 'Parada eliminada correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_BUS_ROUTE,
            variables: {
              id: Number(id),
            },
          });

          client.writeQuery({
            query: GET_BUS_ROUTE,
            variables: {
              id: Number(id),
            },
            data: {
              ...previousData,
              busRoute: {
                ...previousData.busRoute,
                busStops: previousData.busRoute.busStops.filter(
                  b => b.id !== data.result.busStop.id,
                ),
              },
            },
          });
        }
      },
    },
  );

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/bus_routes">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        {busRoute && busRoute.name ? (
          busRoute.name
        ) : (
          <Skeleton paragraph={false} active />
        )}
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="primary" onClick={() => setDisplayNewBusStopForm(true)}>
          Añadir parada
        </Button>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Button
              type="default"
              size="small"
              shape="circle"
              icon="edit"
              onClick={() => {
                setDisplayBusStopForm(record);
              }}
            />
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar la parada?"
              onConfirm={() => destroyBusStop({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card loading={loading} title={header}>
      <BasicTable
        title={() => <Typography.Title level={4}>Paradas</Typography.Title>}
        rowKey="id"
        columns={columns}
        nodes={busRoute.busStops}
        bordered
        loading={destroyingBusStop}
      />

      <Modal visible={!!displayNewBusStopForm} footer={null} closable={false}>
        {!!displayNewBusStopForm && (
          <BusStopForm
            busRoute={busRoute}
            onClose={() => setDisplayNewBusStopForm(false)}
            saving={creatingBusStop}
            errors={createErrors}
            onSubmit={({
              name,
              latitude,
              longitude,
              startsAt,
              endsAt,
              placeId,
            }) => createBusStop({
              variables: {
                attributes: {
                  busRouteId: Number(id),
                  name,
                  latitude: latitude.toString(),
                  longitude: longitude.toString(),
                  startsAt,
                  endsAt,
                  placeId,
                },
              },
            })
            }
          />
        )}
      </Modal>

      <Modal visible={!!displayBusStopForm} footer={null} closable={false}>
        {!!displayBusStopForm && (
          <BusStopForm
            busRoute={busRoute}
            busStop={displayBusStopForm}
            onClose={() => setDisplayBusStopForm(false)}
            saving={updatingBusStop}
            errors={updateErrors}
            onSubmit={({
              id: busStopId,
              name,
              latitude,
              longitude,
              startsAt,
              endsAt,
              placeId,
            }) => updateBusStop({
              variables: {
                id: Number(busStopId),
                attributes: {
                  busRouteId: Number(id),
                  name,
                  latitude,
                  longitude,
                  startsAt,
                  endsAt,
                  placeId,
                },
              },
            })
            }
          />
        )}
      </Modal>
    </Card>
  );
};
