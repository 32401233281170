import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Card,
  Row,
  Col,
  Button,
  Skeleton,
  Typography,
  Modal,
  notification,
  Popconfirm,
} from 'antd';
import { businessFragments, branchFragments } from '../../../fragments';
import BasicTable from '../../shared/basicTable';
import BranchForm from './branches/form';

const GET_BUSINESS = gql`
  query GetBusiness($id: Int!) {
    business: getBusiness(id: $id) {
      ...BusinessFields
      branches {
        ...BranchFields
      }
    }
  }
  ${businessFragments.business}
  ${branchFragments.branch}
`;

const CREATE_BRANCH = gql`
  mutation CreateBranch(
    $attributes: BranchAttributes!
    $branchManagerId: Int!
  ) {
    result: createBranch(
      attributes: $attributes
      branchManagerId: $branchManagerId
    ) {
      branch {
        ...BranchFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${branchFragments.branch}
`;

const UPDATE_BRANCH = gql`
  mutation UpdateBranch(
    $id: Int!
    $attributes: BranchAttributes!
    $branchManagerId: Int!
  ) {
    result: updateBranch(
      id: $id
      attributes: $attributes
      branchManagerId: $branchManagerId
    ) {
      branch {
        ...BranchFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${branchFragments.branch}
`;

const DESTROY_BRANCH = gql`
  mutation DestroyBranch($id: Int!) {
    result: destroyBranch(id: $id) {
      branch {
        ...BranchFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${branchFragments.branch}
`;

export default () => {
  const [displayNewBranchForm, setDisplayNewBranchForm] = useState(false);
  const [displayBranchForm, setDisplayBranchForm] = useState();
  const { id } = useParams();

  const { data: { business = {} } = {}, loading } = useQuery(GET_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: Number(id),
    },
  });

  const [
    createBranch,
    { data: { result: createResult = {} } = {}, loading: creatingBranch },
  ] = useMutation(CREATE_BRANCH, {
    update: (client, { data }) => {
      if (data.result.branch) {
        notification.success({
          message: 'Bien!',
          description: 'Sucursal creado correctamente.',
        });

        setDisplayNewBranchForm(false);

        const previousData = client.readQuery({
          query: GET_BUSINESS,
          variables: {
            id: Number(id),
          },
        });

        client.writeQuery({
          query: GET_BUSINESS,
          variables: {
            id: Number(id),
          },
          data: {
            ...previousData,
            business: {
              ...previousData.business,
              branches: [...previousData.business.branches, data.result.branch],
            },
          },
        });
      }
    },
  });

  const [
    updateBranch,
    { data: { result: updateResult = {} } = {}, loading: updatingBranch },
  ] = useMutation(UPDATE_BRANCH, {
    update: (_, { data }) => {
      if (data.result.branch) {
        notification.success({
          message: 'Bien!',
          description: 'Sucursal actualizada correctamente.',
        });

        setDisplayBranchForm(false);
      }
    },
  });

  const [destroyBranch, { loading: destroyingBranch }] = useMutation(
    DESTROY_BRANCH,
    {
      update: (client, { data }) => {
        if (data.result.branch) {
          notification.success({
            message: 'Bien!',
            description: 'Sucursal eliminada correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_BUSINESS,
            variables: {
              id: Number(id),
            },
          });

          client.writeQuery({
            query: GET_BUSINESS,
            variables: {
              id: Number(id),
            },
            data: {
              ...previousData,
              business: {
                ...previousData.business,
                branches: previousData.business.branches.filter(
                  b => b.id !== data.result.branch.id,
                ),
              },
            },
          });
        }
      },
    },
  );

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/businesses">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        {business && business.name ? (
          business.name
        ) : (
          <Skeleton paragraph={false} active />
        )}
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="primary" onClick={() => setDisplayNewBranchForm(true)}>
          Añadir sucursal
        </Button>
      </Col>
    </Row>
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Provincia',
      dataIndex: 'state.name',
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Administrador de sucursal',
      render: (_, record) => (
        <span>{`${record.branchManager.firstName} ${record.branchManager.lastName}`}</span>
      ),
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Button
              type="default"
              size="small"
              shape="circle"
              icon="edit"
              onClick={() => {
                setDisplayBranchForm(record);
              }}
            />
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar la sucursal?"
              onConfirm={() => destroyBranch({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Card loading={loading} title={header}>
      <BasicTable
        title={() => <Typography.Title level={4}>Sucursales</Typography.Title>}
        rowKey="id"
        columns={columns}
        nodes={business.branches}
        bordered
        loading={destroyingBranch}
      />

      <Modal visible={!!displayNewBranchForm} footer={null} closable={false}>
        {!!displayNewBranchForm && (
          <BranchForm
            onClose={() => setDisplayNewBranchForm(false)}
            saving={creatingBranch}
            errors={createErrors}
            onSubmit={({
              name,
              stateId,
              address,
              latitude,
              longitude,
              description,
              branchManagerId,
            }) => createBranch({
              variables: {
                attributes: {
                  businessId: Number(id),
                  name,
                  stateId,
                  address,
                  latitude,
                  longitude,
                  description,
                },
                branchManagerId: branchManagerId
                  ? Number(branchManagerId)
                  : null,
              },
            })
            }
          />
        )}
      </Modal>

      <Modal visible={!!displayBranchForm} footer={null} closable={false}>
        {!!displayBranchForm && (
          <BranchForm
            branch={displayBranchForm}
            onClose={() => setDisplayBranchForm(false)}
            saving={updatingBranch}
            errors={updateErrors}
            onSubmit={({
              id: branchId,
              name,
              stateId,
              address,
              latitude,
              longitude,
              description,
              branchManagerId,
            }) => updateBranch({
              variables: {
                id: Number(branchId),
                attributes: {
                  businessId: Number(id),
                  name,
                  stateId,
                  address,
                  latitude,
                  longitude,
                  description,
                },
                branchManagerId: branchManagerId
                  ? Number(branchManagerId)
                  : null,
              },
            })
            }
          />
        )}
      </Modal>
    </Card>
  );
};
