import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Typography,
  Row,
  Col,
  Select,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import 'react-google-places-autocomplete/dist/assets/index.css';
import GoogleMapReact from 'google-map-react';

const GET_PLACES = gql`
  query GetPlaces($q: PlaceFilter!) {
    places: getPlaces(q: $q) {
      nodes {
        id
        name
        latitude
        longitude
      }
    }
  }
`;

export default ({
  busRoute = {},
  busStop = {},
  onSubmit,
  errors,
  saving,
  onClose,
}) => {
  const [busStopData, setBusStopData] = useState({});
  const [center, setCenter] = useState({
    lat: -32.8833352,
    lng: -68.8585191,
  });
  const [zoom, setZoom] = useState(12);
  const [path, setPath] = useState([]);

  const { data: { places = {} } = {}, loading: loadingPlaces } = useQuery(
    GET_PLACES,
    {
      variables: {
        q: {
          isBusStopEq: true,
          stateIdEq: busRoute.stateId,
        },
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    if (busStop && busStop.id) {
      setBusStopData(busStop);
      setZoom(17);
      setCenter({
        lat: Number(busStop.latitude),
        lng: Number(busStop.longitude),
      });
    }

    if (busRoute && busRoute.busPoints.length > 0) {
      setPath(
        busRoute.busPoints.map(bs => ({
          latitude: Number(bs.latitude),
          longitude: Number(bs.longitude),
        })),
      );
    }
  }, []);

  function canSubmit() {
    return busStopData.name && busStopData.latitude && busStopData.longitude;
  }

  function handleGoogleMapApi(google) {
    if (path.length > 0) {
      const polyline = new google.maps.Polyline({
        path: path.map(
          e => new google.maps.LatLng(Number(e.latitude), Number(e.longitude)),
        ),
      });

      polyline.setMap(google.map);

      const bounds = new google.maps.LatLngBounds();
      const points = polyline.getPath().getArray();

      for (let i = 0; i < points.length; i += 1) {
        bounds.extend(points[i]);
      }

      google.map.fitBounds(bounds);
    }
  }

  const nameErrors = errors && errors.find(e => e.key === 'name');
  const isEditing = !!busStop.id;
  const { nodes = [] } = places;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar parada' : 'Crear parada'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Nombre"
              validateStatus={nameErrors ? 'error' : ''}
              help={nameErrors && nameErrors.messages.join(', ')}
            >
              <Input
                autoFocus
                type="text"
                placeholder="Nombre"
                value={busStopData.name}
                onChange={e => setBusStopData({
                  ...busStopData,
                  name: e.target.value,
                })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Punto de interés asociado">
              <Select
                allowClear
                loading={loadingPlaces}
                onChange={(placeId) => {
                  if (!placeId) {
                    setBusStopData({
                      ...busStopData,
                      placeId,
                      latitude: null,
                      longitude: null,
                    });

                    return;
                  }

                  const selectedPlace = places.nodes.find(
                    p => p.id === placeId,
                  );

                  setCenter({
                    lat: Number(selectedPlace.latitude),
                    lng: Number(selectedPlace.longitude),
                  });

                  setZoom(17);
                  setBusStopData({
                    ...busStopData,
                    latitude: selectedPlace.latitude,
                    longitude: selectedPlace.longitude,
                    placeId,
                  });
                }}
                value={busStopData.placeId}
                busRouteholder="Seleccione punto de interés"
                showSearch
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
              >
                {nodes.map(place => (
                  <Select.Option key={place.id} value={place.id}>
                    {place.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <div style={{ height: '350px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
              center={center}
              zoom={zoom}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={handleGoogleMapApi}
              onClick={({ lat, lng }) => {
                setBusStopData({
                  ...busStopData,
                  latitude: lat.toString(),
                  longitude: lng.toString(),
                });
              }}
            >
              {busStopData.latitude && busStopData.longitude && (
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="3x"
                  style={{
                    color: '#e30066',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  lat={Number(busStopData.latitude)}
                  lng={Number(busStopData.longitude)}
                />
              )}
            </GoogleMapReact>
          </div>
        </Row>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit(busStopData)}
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
