import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Select,
  Typography,
  Row,
  Col,
  Popconfirm,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import GoogleMapReact from 'google-map-react';
import { geocodeByAddress } from 'react-google-places-autocomplete';

const GET_STATES = gql`
  query GetStates {
    states: getStates {
      id
      name
    }
  }
`;

let polyline = null;

export default ({
  busRoute = {}, onSubmit, errors, saving, onClose,
}) => {
  const [busRouteData, setBusRouteData] = useState({});
  const [center, setCenter] = useState({
    lat: -32.8833352,
    lng: -68.8585191,
  });
  const [zoom, setZoom] = useState(12);
  const [path, setPath] = useState([]);

  const { data: { states = [] } = {}, loading: loadingStates } = useQuery(
    GET_STATES,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(
    () => function cleanUp() {
      polyline = null;
      setBusRouteData({});
    },
    [],
  );

  useEffect(() => {
    if (busRoute && busRoute.id) {
      setBusRouteData(busRoute);

      if (busRoute.busPoints.length > 0) {
        setPath(
          busRoute.busPoints.map(bs => ({
            latitude: Number(bs.latitude),
            longitude: Number(bs.longitude),
          })),
        );
      }
    }
  }, [busRoute]);

  function canSubmit() {
    return busRouteData.name && busRouteData.stateId && path.length > 0;
  }

  function handleGoogleMapApi(google) {
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYLINE,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYLINE],
      },
    });

    drawingManager.setMap(google.map);

    google.maps.event.addListener(
      drawingManager,
      'polylinecomplete',
      (element) => {
        if (polyline) {
          polyline.setMap(null);
        }

        polyline = element;
        setPath(
          polyline
            .getPath()
            .getArray()
            .map(e => ({ latitude: e.lat(), longitude: e.lng() })),
        );
      },
    );

    if (!polyline && path.length > 0) {
      const existingPolyline = new google.maps.Polyline({
        path: path.map(
          e => new google.maps.LatLng(Number(e.latitude), Number(e.longitude)),
        ),
      });

      existingPolyline.setMap(google.map);
      polyline = existingPolyline;

      const bounds = new google.maps.LatLngBounds();
      const points = polyline.getPath().getArray();

      for (let i = 0; i < points.length; i += 1) {
        bounds.extend(points[i]);
      }

      google.map.fitBounds(bounds);
    }
  }

  const nameErrors = errors && errors.find(e => e.key === 'name');
  const isEditing = !!busRouteData.id;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar ruta' : 'Crear ruta'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              label="Nombre"
              validateStatus={nameErrors ? 'error' : ''}
              help={nameErrors && nameErrors.messages.join(', ')}
            >
              <Input
                autoFocus
                type="text"
                placeholder="Nombre"
                value={busRouteData.name}
                onChange={e => setBusRouteData({
                  ...busRouteData,
                  name: e.target.value,
                })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item label="Provincia">
              <Select
                loading={loadingStates}
                onChange={(stateId) => {
                  setBusRouteData({ ...busRouteData, stateId });

                  const selectedState = states.find(s => s.id === stateId);

                  geocodeByAddress(selectedState.name).then((results) => {
                    setCenter({
                      lat: results[0].geometry.location.lat(),
                      lng: results[0].geometry.location.lng(),
                    });

                    setZoom(16);
                  });
                }}
                value={busRouteData.stateId}
                busRouteholder="Seleccione provincia"
                showSearch
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
                }
              >
                {states.map(state => (
                  <Select.Option key={state.id} value={state.id}>
                    {state.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24}>
            <div style={{ height: '350px', width: '100%' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                }}
                center={center}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={handleGoogleMapApi}
              />
            </div>
          </Col>

          <Col xs={24}>
            <Popconfirm
              title="Seguro desea eliminar el trayecto actual?"
              onConfirm={() => {
                setPath([]);

                if (polyline) {
                  polyline.setMap(null);
                  polyline = null;
                }
              }}
              okText="Sí"
              cancelText="No"
            >
              <Button
                style={{ marginTop: 10 }}
                type="danger"
                disabled={path.length === 0}
              >
                Limpiar
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit({
          ...busRouteData,
          busPointsAttributes: path.map(e => ({
            latitude: e.latitude.toString(),
            longitude: e.longitude.toString(),
          })),
        })
        }
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
