import React from 'react';
import { Table } from 'antd';
import snakeCase from 'snake-case';

export default ({
  rowKey,
  columns,
  nodes = [],
  loading,
  onHandleTableChange,
  pagination,
  ...props
}) => {
  function handleTableChange(tablePagination: pagination, filters, sorter) {
    const q = {};

    Object.entries(filters).forEach((filter) => {
      const [key, values] = filter;

      if (values.length === 0) {
        return;
      }

      q[key] = values.length === 1 ? values[0] : values;
    });

    if (sorter.field && sorter.order) {
      q.s = `${snakeCase(sorter.field)} ${
        sorter.order === 'ascend' ? 'asc' : 'desc'
      }`;
    }

    onHandleTableChange(tablePagination, q);
  }

  return (
    <Table
      rowKey={rowKey}
      columns={columns}
      dataSource={nodes}
      loading={loading}
      pagination={pagination}
      onChange={handleTableChange}
      {...props}
    />
  );
};
