import gql from 'graphql-tag';

const VALIDATE_TOKEN = gql`
  query ValidateToken {
    result @rest(type: "User", path: "auth/validate_token/") {
      user: data @type(name: "User") {
        id
        firstName
        lastName
        email
        permissions @type(name: "Permission") {
          can
          actions
          subjects
          conditions
        }
      }
      errors @type(name: "errors") {
        key
        messages
      }
    }
  }
`;

export { VALIDATE_TOKEN };
