import gql from 'graphql-tag';

const userFragments = {
  user: gql`
    fragment UserFields on User {
      id
      firstName
      lastName
      email
      createdAt
      roles {
        id
        name
        displayName
      }
      defaultRole {
        id
        name
        displayName
      }
    }
  `,
};

const businessFragments = {
  business: gql`
    fragment BusinessFields on Business {
      id
      name
      logoUrl
      createdAt
      placeCategoryId
      placeCategory {
        id
        name
        iconPath
      }
    }
  `,
};

const branchFragments = {
  branch: gql`
    fragment BranchFields on Branch {
      id
      name
      stateId
      businessId
      state {
        id
        name
      }
      address
      latitude
      longitude
      description
      notificationRadioInMeters
      createdAt
      branchManagerId
      branchManager {
        id
        firstName
        lastName
        email
      }
      placeCategoryId
      placeCategory {
        id
        name
        iconPath
      }
    }
  `,
};

const discountFragments = {
  discount: gql`
    fragment DiscountFields on Discount {
      id
      title
      startsAt
      endsAt
      isActive
      description
      imageUrl
      branchIds
      createdAt
    }
  `,
};

const placeFragments = {
  place: gql`
    fragment PlaceFields on Place {
      id
      name
      placeCategoryId
      placeCategory {
        id
        name
        iconPath
      }
      latitude
      longitude
      description
      address
      isBusStop
      stateId
      state {
        id
        name
      }
      translations {
        id
        name
        description
        locale
      }
      imageUrl
      audioUrl
      createdAt
    }
  `,
};

const eventItemFragments = {
  eventItem: gql`
    fragment EventItemFields on EventItem {
      id
      name
      latitude
      longitude
      description
      address
      startsAt
      stateId
      state {
        id
        name
      }
      translations {
        id
        name
        description
        locale
      }
      imageUrl
      createdAt
    }
  `,
};

const busStopFragments = {
  busStop: gql`
    fragment BusStopFields on BusStop {
      id
      name
      latitude
      longitude
      placeId
      place {
        id
        name
      }
      createdAt
    }
  `,
};

const busRouteFragments = {
  busRoute: gql`
    fragment BusRouteFields on BusRoute {
      id
      name
      stateId
      state {
        id
        name
      }
      busPoints {
        id
        latitude
        longitude
      }
      busStops {
        ...BusStopFields
      }
      createdAt
    }
    ${busStopFragments.busStop}
  `,
};

export {
  userFragments,
  businessFragments,
  branchFragments,
  discountFragments,
  placeFragments,
  eventItemFragments,
  busRouteFragments,
  busStopFragments,
};
