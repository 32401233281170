import React, { useEffect, useState } from 'react';
import {
  Form,
  Divider,
  Button,
  Input,
  Typography,
  Row,
  Col,
  Upload,
  Icon,
  DatePicker,
  Checkbox,
  Tree,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import getBase64 from '../../../shared/getBase64';

export default ({
  business = {},
  discount = {},
  onSubmit,
  errors,
  saving,
  onClose,
}) => {
  const [discountData, setDiscountData] = useState({});
  const [imagePreviewUrl, setImagePreviewUrl] = useState();

  useEffect(() => {
    if (discount && discount.id) {
      setDiscountData({
        ...discount,
        startsAt: discount.startsAt ? moment(discount.startsAt) : null,
        endsAt: discount.endsAt ? moment(discount.endsAt) : null,
      });

      if (discount.imageUrl) {
        setImagePreviewUrl(
          `${process.env.REACT_APP_API_ENDPOINT}${discount.imageUrl}`,
        );
      }
    }
  }, [discount]);

  function canSubmit() {
    return discountData.title;
  }

  function handleChange(info) {
    getBase64(info.file, (imageUrl) => {
      setDiscountData({
        ...discountData,
        image: info.file,
      });

      setImagePreviewUrl(imageUrl);
    });
  }

  const titleErrors = errors && errors.find(e => e.key === 'title');
  const descriptionErrors = errors && errors.find(e => e.key === 'description');
  const dateRangeErrors = errors && errors.find(e => e.key === 'startsAt' || e.key === 'endsAt');
  const isEditing = !!discount.id;

  return (
    <div>
      <Row>
        <Col xs={23}>
          <Typography.Title level={3}>
            {isEditing ? 'Editar descuento' : 'Crear descuento'}
          </Typography.Title>
        </Col>
        <Col xs={1}>
          <Button type="link" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 22 }} />
          </Button>
        </Col>
      </Row>

      <Form>
        <Row>
          <Col xs={24}>
            <Form.Item
              label="Título"
              validateStatus={titleErrors ? 'error' : ''}
              help={titleErrors && titleErrors.messages.join(', ')}
            >
              <Input
                autoFocus
                type="text"
                placeholder="Título"
                value={discountData.title}
                onChange={e => setDiscountData({
                  ...discountData,
                  title: e.target.value,
                })
                }
              />
            </Form.Item>

            <Form.Item
              label="Descripción"
              validateStatus={descriptionErrors ? 'error' : ''}
              help={descriptionErrors && descriptionErrors.messages.join(', ')}
            >
              <Input.TextArea
                rows={4}
                placeholder="Descripción del descuento"
                value={discountData.description}
                onChange={e => setDiscountData({
                  ...discountData,
                  description: e.target.value,
                })
                }
              />
            </Form.Item>

            <Form.Item
              label="Período de validez"
              validateStatus={dateRangeErrors ? 'error' : ''}
              help={dateRangeErrors && dateRangeErrors.messages.join(', ')}
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                placeholder={['Inicio', 'Fin']}
                format="DD/MM/YYYY"
                value={[discountData.startsAt, discountData.endsAt]}
                onChange={([startsAt, endsAt]) => setDiscountData({ ...discountData, startsAt, endsAt })
                }
                ranges={{
                  'Sólo por hoy': [
                    moment().startOf('day'),
                    moment().endOf('day'),
                  ],
                  'Sólo por mañana': [
                    moment()
                      .add(1, 'day')
                      .startOf('day'),
                    moment()
                      .add(1, 'day')
                      .endOf('day'),
                  ],
                  'Este mes': [
                    moment().startOf('month'),
                    moment().endOf('month'),
                  ],
                  'El próximo mes': [
                    moment()
                      .add(1, 'month')
                      .startOf('month'),
                    moment()
                      .add(1, 'month')
                      .endOf('month'),
                  ],
                }}
              />
            </Form.Item>

            <Form.Item>
              <Checkbox
                checked={discountData.isActive}
                onChange={e => setDiscountData({ ...discountData, isActive: e.target.checked })
                }
              >
                Promoción activa?
              </Checkbox>
            </Form.Item>

            <Form.Item label="Sucursales asociadas">
              <Tree
                checkable
                onCheck={branchIds => setDiscountData({ ...discountData, branchIds })
                }
                checkedKeys={discountData.branchIds}
              >
                {business.branches
                  && business.branches.map(b => (
                    <Tree.TreeNode title={b.name} key={b.id} />
                  ))}
              </Tree>
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Col xs={24}>
          <Form.Item label="Seleccionar imagen promocional">
            <Upload
              accept="image/x-png,image/jpeg"
              name="logo"
              showUploadList={false}
              beforeUpload={() => false}
              onChange={handleChange}
              style={{ cursor: 'pointer' }}
            >
              {imagePreviewUrl ? (
                <img src={imagePreviewUrl} alt="logo" style={{ height: 150 }} />
              ) : (
                <div>
                  <Icon type="plus" />
                  <div className="ant-upload-text">Seleccionar imagen</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        </Col>
      </Form>

      <Divider />

      <Button
        type="primary"
        disabled={saving || !canSubmit()}
        loading={saving}
        onClick={() => onSubmit(discountData)}
      >
        {isEditing ? 'Actualizar' : 'Crear'}
      </Button>
    </div>
  );
};
