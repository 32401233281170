import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import {
  Row,
  Col,
  Button,
  Card,
  Popconfirm,
  notification,
  Modal,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import SearchDropdown from '../../shared/searchDropdown';
import BasicTable from '../../shared/basicTable';
import { busRouteFragments } from '../../../fragments';
import BusRouteForm from './form';

const GET_BUS_ROUTES = gql`
  query GetBusRoutes($q: BusRouteFilter!) {
    busRoutes: getBusRoutes(q: $q) {
      ...BusRouteFields
    }
  }
  ${busRouteFragments.busRoute}
`;

const CREATE_BUS_ROUTE = gql`
  mutation CreateBusRoute($attributes: BusRouteAttributes!) {
    result: createBusRoute(attributes: $attributes) {
      busRoute {
        ...BusRouteFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${busRouteFragments.busRoute}
`;

const UPDATE_BUS_ROUTE = gql`
  mutation UpdateBusRoute($id: Int!, $attributes: BusRouteAttributes!) {
    result: updateBusRoute(id: $id, attributes: $attributes) {
      busRoute {
        ...BusRouteFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${busRouteFragments.busRoute}
`;

const DESTROY_BUS_ROUTE = gql`
  mutation DestroyBusRoute($id: Int!) {
    result: destroyBusRoute(id: $id) {
      busRoute {
        ...BusRouteFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${busRouteFragments.busRoute}
`;

export default ({ history, match }) => {
  const { url } = match;

  const [query, setQuery] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [busRouteFormData, setBusRouteFormData] = useState();

  const { data: { busRoutes = [] } = {}, loading } = useQuery(GET_BUS_ROUTES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      q: query,
    },
  });

  const [
    createBusRoute,
    { data: { result: createResult = {} } = {}, loading: creatingBusRoute },
  ] = useMutation(CREATE_BUS_ROUTE, {
    update: (client, { data }) => {
      if (data.result.busRoute) {
        notification.success({
          message: 'Bien!',
          description: 'Ruta creada correctamente.',
        });

        setBusRouteFormData(null);

        const previousData = client.readQuery({
          query: GET_BUS_ROUTES,
          variables: {
            q: query,
          },
        });

        client.writeQuery({
          query: GET_BUS_ROUTES,
          variables: {
            q: query,
          },
          data: {
            ...previousData,
            busRoutes: [...busRoutes, data.result.busRoute],
          },
        });
      }
    },
  });

  const [
    updateBusRoute,
    { data: { result: updateResult = {} } = {}, loading: updatingBusRoute },
  ] = useMutation(UPDATE_BUS_ROUTE, {
    update: (_, { data }) => {
      if (data.result.busRoute) {
        notification.success({
          message: 'Bien!',
          description: 'Ruta actualizada correctamente.',
        });

        setBusRouteFormData(null);
      }
    },
  });

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const [destroyBusRoute, { loading: destroyingBusRoute }] = useMutation(
    DESTROY_BUS_ROUTE,
    {
      update: (client, { data }) => {
        if (data.result.busRoute) {
          notification.success({
            message: 'Bien!',
            description: 'Ruta eliminada correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_BUS_ROUTES,
            variables: {
              q: query,
            },
          });

          client.writeQuery({
            query: GET_BUS_ROUTES,
            variables: {
              q: query,
            },
            data: {
              ...previousData,
              busRoutes: previousData.busRoutes.filter(
                u => u.id !== data.result.busRoute.id,
              ),
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    const { location } = history;
    const searchObject = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { page, ...queryObject } = searchObject;

    setQuery(queryObject);

    if (queryObject.s) {
      const [columnKey, order] = queryObject.s.split(' ');

      setSortedInfo({
        columnKey,
        order,
      });
    }
  }, [history.location.search]);

  function handleTableChange(pagination, q) {
    history.push({
      search: qs.stringify(q),
    });
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'nameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.name ? [query.name] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Provincia',
      dataIndex: 'state.name',
      key: 'stateNameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'stateNameCont' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.stateNameCont ? [query.stateNameCont] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Link to={`${url}/${record.id}/bus_stops`}>
              <Tooltip title="Paradas">
                <Button
                  type="default"
                  size="small"
                  shape="circle"
                  icon="clock-circle"
                />
              </Tooltip>
            </Link>
          </Col>

          <Col xs={3}>
            <Button
              type="default"
              size="small"
              shape="circle"
              icon="edit"
              onClick={() => setBusRouteFormData(record)}
            />
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar la ruta?"
              onConfirm={() => destroyBusRoute({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        Rutas
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="default" onClick={() => setBusRouteFormData({})}>
          Nueva ruta
        </Button>
      </Col>
    </Row>
  );

  return (
    <Card title={header}>
      <BasicTable
        rowKey="id"
        columns={columns}
        nodes={busRoutes}
        loading={loading || destroyingBusRoute}
        onHandleTableChange={handleTableChange}
      />

      {busRouteFormData && busRouteFormData.id && (
        <Modal visible closable={false} footer={null}>
          <BusRouteForm
            busRoute={busRouteFormData}
            saving={updatingBusRoute}
            errors={updateErrors}
            onClose={() => setBusRouteFormData(null)}
            onSubmit={({
              id, name, stateId, busPointsAttributes,
            }) => updateBusRoute({
              variables: {
                id: Number(id),
                attributes: {
                  name,
                  stateId,
                  busPointsAttributes,
                },
              },
            })
            }
          />
        </Modal>
      )}

      {busRouteFormData && !busRouteFormData.id && (
        <Modal visible closable={false} footer={null}>
          <BusRouteForm
            busRoute={busRouteFormData}
            saving={creatingBusRoute}
            errors={createErrors}
            onClose={() => setBusRouteFormData(null)}
            onSubmit={({ name, stateId, busPointsAttributes }) => createBusRoute({
              variables: {
                attributes: {
                  name,
                  stateId,
                  busPointsAttributes,
                },
              },
            })
            }
          />
        </Modal>
      )}
    </Card>
  );
};
