import styled from 'styled-components';
import background from '../../images/login_background.jpg';

export default styled.div`
  .loginHolder {
    min-height: 100vh;
    background-image: url(${background});
    background-attachment: fixed;
    background-size: cover;

    .loginForm {
      min-height: 95vh;
      padding: 15px;
      background-color: #fff;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      @media only screen and (min-width: 768px) {
        min-height: 60vh;
      }

      .logo {
        height: 90px;
        margin: 15px;
      }

      .ant-input {
        margin-bottom: 15px;
      }

      .loginButtonContainer {
        margin-top: -200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .loginButton {
          width: 50%;
          background-color: #fab317;
          font: Bold 15px/20px Quicksand;
          color: white;
          border: unset;
          border-radius: 15px;
          margin: 0 0 10px;
        }
        a {
          font: Bold 15px/20px Quicksand;
          color: #fab317;
        }
      }

      .registerText {
        text-align: center;
        font: Bold 15px/20px Quicksand;
        color: #35393b;
        a {
          color: #fab317;
        }
      }
    }
  }
`;
