import styled from 'styled-components';

export default styled.div`
  img.logo-small {
    width: 200px;
  }

  img.logo-header {
    width: 200px;
  }

  .ant-layout-sider {
    background-color: #e30066;
    margin-top: -4px;

    .ant-menu {
      background-color: #e30066;

      .ant-menu-item {
        font-weight: bold;
        font-size: 14px;
        font-family: Quicksand;

        a,
        span,
        i {
          color: white;
        }
      }
    }
  }
`;
