import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginComponent from './components/login';
import AppComponent from './components/app';
import ScrollToTop from './components/helpers/scrollToTop';

export default () => (
  <ScrollToTop>
    <Switch>
      <Route exact path="/login" component={LoginComponent} />
      <Route path="/" component={AppComponent} />
    </Switch>
  </ScrollToTop>
);
