import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import {
  Row, Col, Button, Card, Popconfirm, notification, Modal,
} from 'antd';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchDropdown from '../../shared/searchDropdown';
import BasicTable from '../../shared/basicTable';
import { placeFragments } from '../../../fragments';
import PlaceForm from './form';

const GET_PLACES = gql`
  query GetPlaces($q: PlaceFilter!, $pagination: PaginationFilter) {
    places: getPlaces(q: $q, pagination: $pagination) {
      nodes {
        ...PlaceFields
      }
      totalCount
      totalPageCount
    }
  }
  ${placeFragments.place}
`;

const CREATE_PLACE = gql`
  mutation CreatePlace($attributes: PlaceAttributes!) {
    result: createPlace(attributes: $attributes) {
      place {
        ...PlaceFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${placeFragments.place}
`;

const UPDATE_PLACE = gql`
  mutation UpdatePlace($id: Int!, $attributes: PlaceAttributes!) {
    result: updatePlace(id: $id, attributes: $attributes) {
      place {
        ...PlaceFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${placeFragments.place}
`;

const DESTROY_PLACE = gql`
  mutation DestroyPlace($id: Int!) {
    result: destroyPlace(id: $id) {
      place {
        ...PlaceFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${placeFragments.place}
`;

export default ({ history }) => {
  const [query, setQuery] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [placeFormData, setPlaceFormData] = useState();

  const { data: { places = [] } = {}, loading } = useQuery(GET_PLACES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      q: query,
      pagination: {
        page: pagination.current,
        perPage: pagination.pageSize,
      },
    },
  });

  const [
    createPlace,
    { data: { result: createResult = {} } = {}, loading: creatingPlace },
  ] = useMutation(CREATE_PLACE, {
    update: (client, { data }) => {
      if (data.result.place) {
        notification.success({
          message: 'Bien!',
          description: 'Punto de interés creado correctamente.',
        });

        setPlaceFormData(null);

        const previousData = client.readQuery({
          query: GET_PLACES,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
        });

        client.writeQuery({
          query: GET_PLACES,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
          data: {
            ...previousData,
            places: {
              ...places,
              nodes: [...previousData.places.nodes, data.result.place],
            },
          },
        });
      }
    },
  });

  const [
    updatePlace,
    { data: { result: updateResult = {} } = {}, loading: updatingPlace },
  ] = useMutation(UPDATE_PLACE, {
    update: (_, { data }) => {
      if (data.result.place) {
        notification.success({
          message: 'Bien!',
          description: 'Punto de interés actualizado correctamente.',
        });

        setPlaceFormData(null);
      }
    },
  });

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const [destroyPlace, { loading: destroyingPlace }] = useMutation(
    DESTROY_PLACE,
    {
      update: (client, { data }) => {
        if (data.result.place) {
          notification.success({
            message: 'Bien!',
            description: 'Punto de interés eliminado correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_PLACES,
            variables: {
              q: query,
              pagination: {
                page: pagination.current,
                perPage: pagination.pageSize,
              },
            },
          });

          client.writeQuery({
            query: GET_PLACES,
            variables: {
              q: query,
              pagination: {
                page: pagination.current,
                perPage: pagination.pageSize,
              },
            },
            data: {
              ...previousData,
              places: {
                ...places,
                nodes: previousData.places.nodes.filter(
                  u => u.id !== data.result.place.id,
                ),
              },
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    const { location } = history;
    const searchObject = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { page, ...queryObject } = searchObject;

    setQuery(queryObject);

    if (queryObject.s) {
      const [columnKey, order] = queryObject.s.split(' ');

      setSortedInfo({
        columnKey,
        order,
      });
    }

    if (page) {
      setPagination({
        current: Number(page),
        pageSize: 30,
      });
    }
  }, [history.location.search]);

  function handleTableChange(tablePagination: pagination, q) {
    history.push({
      search: qs.stringify({ ...q, page: tablePagination.current }),
    });
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'translationsNameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.translationsNameCont ? [query.translationsNameCont] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Provincia',
      dataIndex: 'state.name',
      key: 'stateNameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'stateNameCont' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.stateNameCont ? [query.stateNameCont] : [],
      render: text => <span>{text}</span>,
      width: 150,
    },
    {
      title: 'Categoría',
      dataIndex: 'placeCategory.name',
      key: 'placeCategoryTranslationsNameCont',
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === 'placeCategoryTranslationsNameCont'
        && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.placeCategoryTranslationsNameCont
        ? [query.placeCategoryTranslationsNameCont]
        : [],
      render: (text, { placeCategory: { iconPath } }) => (
        <span>
          {text}
          {iconPath && <FontAwesomeIcon icon={['fal', iconPath.replace('fa-', '')]} style={{ fontSize: 22, marginLeft: 10 }} />}
        </span>
      ),
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Button
              type="default"
              size="small"
              shape="circle"
              icon="edit"
              onClick={() => setPlaceFormData(record)}
            />
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar el punto de interés?"
              onConfirm={() => destroyPlace({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Button type="danger" size="small" shape="circle" icon="delete" />
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        Punto de interés
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="default" onClick={() => setPlaceFormData({})}>
          Nuevo punto de interés
        </Button>
      </Col>
    </Row>
  );

  const { nodes, totalCount } = places;

  return (
    <Card title={header}>
      <BasicTable
        rowKey="id"
        columns={columns}
        nodes={nodes}
        loading={loading || destroyingPlace}
        onHandleTableChange={handleTableChange}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
      />

      {placeFormData && placeFormData.id && (
        <Modal visible closable={false} footer={null}>
          <PlaceForm
            place={placeFormData}
            saving={updatingPlace}
            errors={updateErrors}
            onClose={() => setPlaceFormData(null)}
            onSubmit={({
              id,
              translationsAttributes,
              placeCategoryId,
              latitude,
              longitude,
              address,
              stateId,
              image,
              audio,
              isBusStop,
            }) => updatePlace({
              variables: {
                id: Number(id),
                attributes: {
                  translationsAttributes,
                  placeCategoryId,
                  latitude,
                  longitude,
                  address,
                  stateId,
                  image,
                  audio,
                  isBusStop,
                },
              },
            })
            }
          />
        </Modal>
      )}

      {placeFormData && !placeFormData.id && (
        <Modal visible closable={false} footer={null}>
          <PlaceForm
            place={placeFormData}
            saving={creatingPlace}
            errors={createErrors}
            onClose={() => setPlaceFormData(null)}
            onSubmit={({
              translationsAttributes,
              placeCategoryId,
              latitude,
              longitude,
              address,
              stateId,
              image,
              audio,
              isBusStop,
            }) => createPlace({
              variables: {
                attributes: {
                  translationsAttributes,
                  placeCategoryId,
                  latitude,
                  longitude,
                  address,
                  stateId,
                  image,
                  audio,
                  isBusStop,
                },
              },
            })
            }
          />
        </Modal>
      )}
    </Card>
  );
};
