import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import qs from 'qs';
import moment from 'moment';
import {
  Row,
  Col,
  Button,
  Card,
  Popconfirm,
  notification,
  Modal,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import { usePrevious } from 'react-hanger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SearchDropdown from '../../shared/searchDropdown';
import BasicTable from '../../shared/basicTable';
import { businessFragments } from '../../../fragments';
import BusinessForm from './form';

const GET_BUSINESSES = gql`
  query GetBusinesses($q: BusinessFilter!, $pagination: PaginationFilter) {
    businesses: getBusinesses(q: $q, pagination: $pagination) {
      nodes {
        ...BusinessFields
      }
      totalCount
      totalPageCount
    }
  }
  ${businessFragments.business}
`;

const GET_BUSINESS = gql`
  query GetBusiness($id: Int!) {
    business: getBusiness(id: $id) {
      ...BusinessFields
      branches {
        id
        name
      }
    }
  }
  ${businessFragments.business}
`;

const CREATE_BUSINESS = gql`
  mutation CreateBusiness($attributes: BusinessAttributes!) {
    result: createBusiness(attributes: $attributes) {
      business {
        ...BusinessFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${businessFragments.business}
`;

const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness($id: Int!, $attributes: BusinessAttributes!) {
    result: updateBusiness(id: $id, attributes: $attributes) {
      business {
        ...BusinessFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${businessFragments.business}
`;

const DESTROY_BUSINESS = gql`
  mutation DestroyBusiness($id: Int!) {
    result: destroyBusiness(id: $id) {
      business {
        ...BusinessFields
      }
      errors {
        key
        messages
      }
    }
  }
  ${businessFragments.business}
`;

export default ({ history, match }) => {
  const { url } = match;
  const [query, setQuery] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
  });
  const [businessFormData, setBusinessFormData] = useState();

  const { data: { businesses = [] } = {}, loading } = useQuery(GET_BUSINESSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      q: query,
      pagination: {
        page: pagination.current,
        perPage: pagination.pageSize,
      },
    },
  });

  const [
    getBusiness,
    { data: { business = {} } = {}, loading: loadingBusiness },
  ] = useLazyQuery(GET_BUSINESS, {
    fetchPolicy: 'cache-and-network',
  });

  const wasLoadingBusiness = usePrevious(loadingBusiness);

  useEffect(() => {
    if (wasLoadingBusiness && !loadingBusiness && business && business.id) {
      setBusinessFormData(business);
    }
  }, [business]);

  const [
    createBusiness,
    { data: { result: createResult = {} } = {}, loading: creatingBusiness },
  ] = useMutation(CREATE_BUSINESS, {
    update: (client, { data }) => {
      if (data.result.business) {
        notification.success({
          message: 'Bien!',
          description: 'Comercio creado correctamente.',
        });

        setBusinessFormData(null);

        const previousData = client.readQuery({
          query: GET_BUSINESSES,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
        });

        client.writeQuery({
          query: GET_BUSINESSES,
          variables: {
            q: query,
            pagination: {
              page: pagination.current,
              perPage: pagination.pageSize,
            },
          },
          data: {
            ...previousData,
            businesses: {
              ...businesses,
              nodes: [...previousData.businesses.nodes, data.result.business],
            },
          },
        });
      }
    },
  });

  const [
    updateBusiness,
    { data: { result: updateResult = {} } = {}, loading: updatingBusiness },
  ] = useMutation(UPDATE_BUSINESS, {
    update: (_, { data }) => {
      if (data.result.business) {
        notification.success({
          message: 'Bien!',
          description: 'Comercio actualizado correctamente.',
        });

        setBusinessFormData(null);
      }
    },
  });

  const { errors: createErrors } = createResult;
  const { errors: updateErrors } = updateResult;

  const [destroyBusiness, { loading: destroyingBusiness }] = useMutation(
    DESTROY_BUSINESS,
    {
      update: (client, { data }) => {
        if (data.result.business) {
          notification.success({
            message: 'Bien!',
            description: 'Comercio eliminado correctamente.',
          });

          const previousData = client.readQuery({
            query: GET_BUSINESSES,
            variables: {
              q: query,
              pagination: {
                page: pagination.current,
                perPage: pagination.pageSize,
              },
            },
          });

          client.writeQuery({
            query: GET_BUSINESSES,
            variables: {
              q: query,
              pagination: {
                page: pagination.current,
                perPage: pagination.pageSize,
              },
            },
            data: {
              ...previousData,
              businesses: {
                ...businesses,
                nodes: previousData.businesses.nodes.filter(
                  u => u.id !== data.result.business.id,
                ),
              },
            },
          });
        }
      },
    },
  );

  useEffect(() => {
    const { location } = history;
    const searchObject = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { page, ...queryObject } = searchObject;

    setQuery(queryObject);

    if (queryObject.s) {
      const [columnKey, order] = queryObject.s.split(' ');

      setSortedInfo({
        columnKey,
        order,
      });
    }

    if (page) {
      setPagination({
        current: Number(page),
        pageSize: 30,
      });
    }
  }, [history.location.search]);

  function handleTableChange(tablePagination: pagination, q) {
    history.push({
      search: qs.stringify({ ...q, page: tablePagination.current }),
    });
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'nameCont',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.nameCont ? [query.nameCont] : [],
      render: (text, record) => <Link to={`${url}/${record.id}`}>{text}</Link>,
      width: 150,
    },
    {
      title: 'Categoría',
      dataIndex: 'placeCategory.name',
      key: 'placeCategoryTranslationsNameCont',
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === 'placeCategoryTranslationsNameCont'
        && sortedInfo.order,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <SearchDropdown
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
        />
      ),
      filteredValue: query.placeCategoryTranslationsNameCont
        ? [query.placeCategoryTranslationsNameCont]
        : [],
      render: (text, { placeCategory: { iconPath } }) => (
        <span>
          {text}
          {iconPath && <FontAwesomeIcon icon={['fal', iconPath.replace('fa-', '')]} style={{ fontSize: 22, marginLeft: 10 }} />}
        </span>
      ),
      width: 150,
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
      render: (_, record) => (
        <span>{moment(record.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      ),
      width: 150,
    },
    {
      key: 'action',
      width: 200,
      render: (_, record) => (
        <Row gutter={16}>
          <Col xs={3}>
            <Link to={`${url}/${record.id}`}>
              <Tooltip title="Sucursales">
                <Button
                  type="default"
                  size="small"
                  shape="circle"
                  icon="shop"
                />
              </Tooltip>
            </Link>
          </Col>

          <Col xs={3}>
            <Link to={`${url}/${record.id}/discounts`}>
              <Tooltip title="Descuentos">
                <Button
                  type="default"
                  size="small"
                  shape="circle"
                  icon="percentage"
                />
              </Tooltip>
            </Link>
          </Col>

          <Col xs={3}>
            <Tooltip title="Editar">
              <Button
                type="default"
                size="small"
                shape="circle"
                icon="edit"
                onClick={() => {
                  setBusinessFormData(record);
                  getBusiness({
                    variables: {
                      id: Number(record.id),
                    },
                  });
                }}
              />
            </Tooltip>
          </Col>

          <Col xs={3}>
            <Popconfirm
              title="Seguro desea eliminar el comercio?"
              onConfirm={() => destroyBusiness({
                variables: {
                  id: Number(record.id),
                },
              })
              }
              okText="Sí"
              cancelText="No"
            >
              <Tooltip title="Eliminar">
                <Button
                  type="danger"
                  size="small"
                  shape="circle"
                  icon="delete"
                />
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  const header = (
    <Row type="flex" justify="space-around" align="middle">
      <Col xs={6} sm={4} md={2} lg={2}>
        <Link to="/">
          <Button shape="circle" icon="left" />
        </Link>
      </Col>
      <Col xs={18} sm={10} md={16} lg={18}>
        Comercios
      </Col>
      <Col xs={24} sm={10} md={6} lg={4}>
        <Button type="default" onClick={() => setBusinessFormData({})}>
          Nuevo comercio
        </Button>
      </Col>
    </Row>
  );

  const { nodes, totalCount } = businesses;

  return (
    <Card title={header}>
      <BasicTable
        rowKey="id"
        columns={columns}
        nodes={nodes}
        loading={loading || destroyingBusiness}
        onHandleTableChange={handleTableChange}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
      />

      {businessFormData && businessFormData.id && (
        <Modal visible closable={false} footer={null}>
          <BusinessForm
            business={businessFormData}
            saving={updatingBusiness}
            loading={loadingBusiness}
            errors={updateErrors}
            onClose={() => setBusinessFormData(null)}
            onSubmit={({
              id,
              name,
              placeCategoryId,
              logo,
            }) => updateBusiness({
              variables: {
                id: Number(id),
                attributes: {
                  name,
                  placeCategoryId,
                  logo,
                },
              },
            })
            }
          />
        </Modal>
      )}

      {businessFormData && !businessFormData.id && (
        <Modal visible closable={false} footer={null}>
          <BusinessForm
            business={businessFormData}
            saving={creatingBusiness}
            errors={createErrors}
            onClose={() => setBusinessFormData(null)}
            onSubmit={({ name, placeCategoryId, logo }) => createBusiness({
              variables: {
                attributes: {
                  name,
                  placeCategoryId,
                  logo,
                },
              },
            })
            }
          />
        </Modal>
      )}
    </Card>
  );
};
